import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'whisky-edit',
  templateUrl: './whisky-edit.component.html',
  styleUrls: ['./whisky-edit.component.less']
})

export class WhiskyEditComponent implements OnInit {

  public whiskyId: number;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    var snapshot = this.activatedRoute.snapshot;
    this. whiskyId = Number(snapshot.params.whiskyId);
    console.log(this.whiskyId);

    if (this.whiskyId != 0) {
      // loading data to prefill form fields

    }
  }

}
