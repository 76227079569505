import { Component, OnInit, ViewChild, Inject, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { BottleState, Whisky } from 'src/app/model/whisky';
import { WhiskyBottle } from 'src/app/model/whiskyBottle'
import { WhiskyService } from 'src/app/services/whisky.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatRipple } from '@angular/material/core';

import * as moment from 'moment';

export interface WhiskyBottleElement {
  status: string;
  label: string;
  added: Date;
  opened: Date;
  finished: Date;
  paid: number; 
  id: number;
}
export interface DialogData {
  bottle: WhiskyBottle;
}

const API_USERNAME = environment.apiUserName;
const API_PASSWORD = environment.apiPassword;

const API_URL = environment.apiUrl;
const AUTH_DATA = 'Basic ' + btoa(API_USERNAME + ':' + API_PASSWORD);
const HEADER = { 'Authorization': AUTH_DATA}

@Component({
  selector: 'whisky-bottle-admin',
  templateUrl: './whisky-bottle-admin.component.html',
  styleUrls: ['./whisky-bottle-admin.component.less']
})
export class WhiskyBottleAdminComponent implements OnInit {

  displayedColumns: string[] = ['status', 'label', 'added', 'opened', 'finished'];
  internalDataSource: WhiskyBottleElement[] = [];
  dataSource: MatTableDataSource<WhiskyBottleElement>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /** Reference to the directive instance of the ripple. */
  @ViewChildren(MatRipple) rippleList: QueryList<MatRipple>;

  public whiskyId: number;
  public whisky: Whisky;

  constructor(
      private whiskyService: WhiskyService,
      private activatedRoute: ActivatedRoute,
      public dialog: MatDialog,
      private http: HttpClient
      ) { }

  ngOnInit(): void {
    var snapshot = this.activatedRoute.snapshot;
    this. whiskyId = Number(snapshot.params.whiskyId);
    console.log(this.whiskyId);

    this.loadWhisky();
    this.loadBottles();
  }



  // Load whisky from database
  private loadWhisky(): void {
    if (this.whiskyId != 0) {
      this.whiskyService.getWhiskyById(this.whiskyId)
      .subscribe(
        (whiskies) => { 
          whiskies.forEach(whisky => {
            this.whisky = whisky;
          });
        });
    } 
  }



  // Load bottles from database
  private loadBottles(): void {
    if (this.whiskyId != 0) {
      this.whiskyService.getWhiskyBottlesById(this.whiskyId)
      .subscribe(
          (bottles) => {
            this.internalDataSource = [];
            bottles.forEach(bottle => {
              let b = {
                status: bottle.status,
                label: bottle.status,
                added: null,
                opened: null,
                finished: null,
                paid: null,
                id: bottle.id
              };
              if (bottle.added != null) {
                b.added = new Date(bottle.added);
              }
              if (bottle.opened != null) {
                b.opened = new Date(bottle.opened);
              }
              if (bottle.finished != null) {
                b.finished = new Date(bottle.finished);
              }
              this.internalDataSource.push(b);
            });
            this.dataSource = new MatTableDataSource(this.internalDataSource);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
    }
  }


  
  addNewBottle(): void {
    let whiskyBottle = new WhiskyBottle();
    whiskyBottle.status = BottleState.Closed;
    whiskyBottle.whiskyId = this.whiskyId;
    whiskyBottle.added = new Date();
    const dialogRef = this.dialog.open(WhiskyBottleAdminAddComponent, {
      data: {
        bottle: whiskyBottle
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog added result: ${result.bottle.added}`);
      this.submitBottle(result.bottle);
    });
  }

  submitBottle(whiskyBottle: WhiskyBottle) {
    let body = {
      whisky: whiskyBottle.whiskyId,
      status: whiskyBottle.status,
      added: null,
      opened: null,
      finished: null,
      paid: whiskyBottle.paid
    };

    if (whiskyBottle.added) {
      body.added = (moment(whiskyBottle.added)).format('YYYY-MM-DD')
    }
    if (whiskyBottle.opened) {
      body.opened = (moment(whiskyBottle.opened)).format('YYYY-MM-DD')
    }
    if (whiskyBottle.finished) {
      body.finished = (moment(whiskyBottle.finished)).format('YYYY-MM-DD')
    }



    this.http.post(API_URL + whiskyBottle.whiskyId + "/bottles", JSON.stringify(body),  { headers: HEADER, observe: "response", responseType: 'text'})
        .subscribe(
          (response) => {
            if (response.status == 201) {
              this.loadBottles();
            } else {
              console.log(response);
            }
          }
        );
  }









  editBottle(bottle, index): void {
    const enterDuration = 300;
    const exitDuration = 50;
    const animationDuration = enterDuration + exitDuration;

    let ripples = this.rippleList.toArray()
    ripples[index].launch({centered: true, animation: {enterDuration, exitDuration} });

    setTimeout(() => this.openBottleEditDialog(bottle), animationDuration);
  }

  openBottleEditDialog(bottle: WhiskyBottleElement) {
    let whiskyBottle = new WhiskyBottle();
    whiskyBottle.id = bottle.id;
    whiskyBottle.status = bottle.status as BottleState;
    whiskyBottle.whiskyId = this.whiskyId;
    whiskyBottle.added = bottle.added;
    whiskyBottle.opened = bottle.opened;
    whiskyBottle.finished = bottle.finished;
    whiskyBottle.paid = bottle.paid;
    const dialogRef = this.dialog.open(WhiskyBottleAdminAddComponent, {
      data: {
        bottle: whiskyBottle
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result.bottle.finished);
      this.updateBottle(result.bottle);
    });
  }

  

  updateBottle(whiskyBottle: WhiskyBottle) {
    let body = {
      bottle: whiskyBottle.id,
      status: whiskyBottle.status,
      added: null,
      opened: null,
      finished: null,
      paid: whiskyBottle.paid
    };

    if (whiskyBottle.added) {
      body.added = (moment(whiskyBottle.added)).format('YYYY-MM-DD')
    }
    if (whiskyBottle.opened) {
      body.opened = (moment(whiskyBottle.opened)).format('YYYY-MM-DD')
    }
    if (whiskyBottle.finished) {
      body.finished = (moment(whiskyBottle.finished)).format('YYYY-MM-DD')
    }

    this.http.put(API_URL + this.whiskyId + "/bottles", JSON.stringify(body),  { headers: HEADER, observe: "response", responseType: 'text'})
        .subscribe(
          (response) => {
            if (response.status == 201) {
              this.loadBottles();
            } else {
              console.log(response);
            }
          }
        );
  }


}


@Component({
  selector: 'whisky-bottle-admin-add',
  templateUrl: './whisky-bottle-admin-add.component.html',
  styleUrls: ['./whisky-bottle-admin-add.component.less']
})
export class WhiskyBottleAdminAddComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}

}