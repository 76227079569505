export class Whisky {
    private _id: number;
    public name: string;
    public brand: string;
    public distilleries: string;
    public age: number;
    public vintage: number;
    public bottledBy: string;
    public bottling: string;
    public bottled: string;
    public bottledFor: string;
    public category: string;
    public strength: number;
    public strengthUnit: string;
    public region: string;
    public status: BottleState;
    public caskType: string;
    public caskNumber: string;
    public numberOfBottles: number;
    public bottleNumber: number;
    public myRating: number;
    public paid: number;
    public value: number;
    public photoPreviewUrl: string;
    public photoUrl: string;

    public numberOfBottlesInCollection: number;

    constructor (bottleId: number) {
        this._id = bottleId;
    }

    public get id() {
        return this._id;
    }
}

export enum BottleState {
    Closed = "closed",
    Open = "open",
    Empty = "empty"
}