import { Component, OnInit } from '@angular/core';

import { WhiskyService } from '../../../services/whisky.service';
import { Whisky } from '../../../model/whisky';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

const API_USERNAME = environment.apiUserName;
const API_PASSWORD = environment.apiPassword;

const API_URL = environment.apiUrl;
const AUTH_DATA = 'Basic ' + btoa(API_USERNAME + ':' + API_PASSWORD);
const HEADER = { 'Authorization': AUTH_DATA}

@Component({
  selector: 'whisky-add',
  templateUrl: './whisky-add.component.html',
  styleUrls: ['./whisky-add.component.less']
})
export class WhiskyAddComponent implements OnInit {

  public whiskybaseId: string;

  public parsedWhiskyname: string;
  

  public brand: string;
  public distilleries: string;
  public age: number;
  public vintage: number;
  public bottledBy: string;
  public bottledFor: string;
  public bottling: string;
  public bottled: string;
  public category: string;
  public strength: number;
  public strengthUnit: string;
  public region: string;
  public caskType: string;
  public caskNumber: string;
  public numberOfBottles: number;
  public value: number;
  public photoPreviewUrl: string;
  public photoUrl: string;

  private whiskybaseTimeout;
  private whiskyDom;


  constructor(
      public whiskyService: WhiskyService,
      private http: HttpClient,
      private router: Router
    ) { }

  ngOnInit(): void {

  }

  submitWhisky() {
    let data = {
      id: this.whiskybaseId,
      name: this.parsedWhiskyname,
      brand: this.brand,
      distilleries: this.distilleries,
      age: this.age,
      vintage: this.vintage,
      bottledBy: this.bottledBy,
      bottledFor: this.bottledFor,
      bottling: this.bottling,
      bottled: this.bottled,
      category: this.category,
      strength: this.strength,
      strengthUnit: this.strengthUnit,
      region: this.region,
      caskType: this.caskType,
      caskNumber: this.caskNumber,
      numberOfBottles: this.numberOfBottles,
      value: this.value,
      photoPreviewUrl: this.photoPreviewUrl,
      photoUrl: this.photoUrl
    }
    this.http.post(API_URL, JSON.stringify(data), { headers: HEADER, observe: "response", responseType: 'text'})
        .subscribe(
          (response) => {
            if (response.status == 201) {
              this.router.navigate(['/admin']);
            } else {
              console.log(response);
            }
          }
        );
  }

  onWhiskyIdChange() {
    clearTimeout(this.whiskybaseTimeout);
    this.whiskybaseTimeout = setTimeout(this.verifyWhiskybaseId, 1000, this);
    console.log(this.whiskybaseTimeout);
  }

  verifyWhiskybaseId(object: WhiskyAddComponent) {
    object.whiskyService.verifyWhiskyId(object.whiskybaseId)
    .subscribe(
      (response) => {
          if (response.ok) {
              let domParser = new DOMParser();
              let doc = domParser.parseFromString(response.body, 'text/html');
              object.parsedWhiskyname = doc.querySelectorAll("title")[0].innerText.replace(" - Ratings and reviews - Whiskybase","");
              if (object.parsedWhiskyname.startsWith("Oops")) {
                  console.log("Whisky not found");
                  object.parsedWhiskyname = "";
              } else {

                // found whisky and parse its information
                console.log("Found whisky name: " +  object.parsedWhiskyname);
                object.whiskyDom = doc;

              }
          } else {
              console.log("Whisky not found");
              object.parsedWhiskyname = "";
          }
      }
    );
  }

  loadWhiskyData() {
    if (this.whiskyDom == null) { return; }

    let parsedData : { [key:string]:string; } = {};
    let divs = this.whiskyDom.querySelectorAll("div");
    divs.forEach(div => {

      // brand
      if (div.id == "whisky-header") {
        this.brand = div.querySelector("h1").querySelector("a").innerText;
      }

      // distilleries
      if (div.id ==  "whisky-distillery-list") {
        this.distilleries = div.innerText;
      }

      // other whisky details
      if (div.id == "whisky-details") {

        let dl = div.querySelector("dl");
        let header = dl.querySelectorAll("dt");
        let data = dl.querySelectorAll("dd");
        
        header.forEach((head, index) => {
          parsedData[head.innerText] = data[index].innerText;
          console.log(head.innerText + ": " + data[index].innerText);
        });

        // age
        if (parsedData["Stated Age"] != null) {
          this.age = Number(parsedData["Stated Age"].replace(" years old", ""));
        }

        // vintage
        if (parsedData["Vintage"] != null) {
          this.vintage = Number(parsedData["Vintage"]);
        }

        // bottled by
        if (parsedData["Bottler"] != null) {
          this.bottledBy = parsedData["Bottler"];
        }

        // bottled for
        if (parsedData["Bottled for"] != null) {
          this.bottledFor = parsedData["Bottled for"];
        }

        // Bottling
        if (parsedData["Bottling serie"] != null) {
          this.bottling = parsedData["Bottling serie"];
        }

        // bottled at
        if (parsedData["Bottled"] != null) {
          this.bottled = parsedData["Bottled"];
        }

        // category
        if (parsedData["Category"] != null) {
          this.category = parsedData["Category"];
        }

        // strength
        if (parsedData["Strength"] != null) {
          this.strength = Number(parsedData["Strength"].replace("% Vol.", ""));
        }

        // strength unit (default from whiskybase is "vol.")
        this.strengthUnit = "% Vol.";

        // cask type
        if (parsedData["Casktype"] != null) {
          this.caskType = parsedData["Casktype"];
        }

        // cask number
        if (parsedData["Casknumber"] != null) {
          this.caskNumber = parsedData["Casknumber"];
        }

        // number of bottles
        if (parsedData["Number of bottles"] != null) {
          this.numberOfBottles = Number(parsedData["Number of bottles"]);
        }
      }

    });

  }

}
