import { Component, OnInit, Input } from '@angular/core';
import {FormControl} from '@angular/forms';
import { WhiskyService } from '../../../services/whisky.service';
import { Whisky, BottleState } from '../../../model/whisky';
 
@Component({
  selector: 'whisky-list',
  templateUrl: './whisky-list.component.html',
  styleUrls: ['./whisky-list.component.less']
})

export class WhiskyListComponent implements OnInit {

  @Input() renderingStyle: string;

  bottleStateControl = new FormControl([]);
  bottleStateList: string[] = [BottleState.Open, BottleState.Closed, BottleState.Empty];

  showEmpty: boolean = false;
  showOpen: boolean = true;
  showClosed: boolean = false;


  whiskyCollection: Whisky[] = [];

  filteredCollection: Whisky[] = []

  constructor(private whiskyService: WhiskyService) { }

  ngOnInit() {   
    let init = [];
    init.push(BottleState.Open);
    this.bottleStateControl.setValue(init);

    this.whiskyService.getAllWhiskies()
    .subscribe(
      (whiskies) => { 
        whiskies.sort((a, b) => a.name.localeCompare(b.name));
        this.whiskyCollection = whiskies; 
        this.updateWhisky();
      } 
    );
    
    this.bottleStateControl.valueChanges.subscribe(
      (state) => {
        this.updateWhisky();
      }
    );
    
    
  }

  updateWhisky() {
    this.filteredCollection = [];
    let states = this.bottleStateControl.value as string[];
    this.showEmpty = false;
    this.showOpen = false;
    this.showClosed = false;
    states.forEach(state => {
      if (state == BottleState.Empty) {
        this.showEmpty = true;
      } 
      if (state == BottleState.Closed) {
        this.showClosed = true;
      } 
      if (state == BottleState.Open) {
        this.showOpen = true;
      } 
    });

    this.whiskyCollection.forEach(whisky => {
      // processing empty bottles
      if (whisky.status == BottleState.Empty && this.showEmpty) {
        this.filteredCollection.push(whisky);
      } 

      // processing open bottles
      if (whisky.status == BottleState.Open && this.showOpen) {
        this.filteredCollection.push(whisky);
      }

      // processing closed bottles
      if (whisky.status == BottleState.Closed && this.showClosed) {
        this.filteredCollection.push(whisky);
      }
    });

    this.filteredCollection.sort((a, b) => a.name.localeCompare(b.name));
  }


  onStateRemoved(state: string) {
    const states = this.bottleStateControl.value as string[];
    this.removeFirst(states, state);
    this.bottleStateControl.setValue([]);
    this.bottleStateControl.setValue(states); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }


}
