import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

import { Whisky } from '../model/whisky';
import { WhiskyBottle } from '../model/whiskyBottle';

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from "@angular/common/http";

import {  Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';


const API_URL = environment.apiUrl;
const WHISKYBASE_URL = environment.whiskybaseUrl;

const API_USERNAME = environment.apiUserName;
const API_PASSWORD = environment.apiPassword;

const AUTH_DATA = 'Basic ' + btoa(API_USERNAME + ':' + API_PASSWORD);
const HEADER = { 'Authorization': AUTH_DATA}
interface ResponseWhiskies {
  results: Whisky[];
}


@Injectable({
  providedIn: 'root'
})

export class APIService {

  constructor(
    private http: HttpClient
  ) { }

  // API: GET /whiskies
  public getAllWhiskies(): Observable<Whisky[]>  {
    return this.http
    .get<Whisky[]>(API_URL, { headers: HEADER })
    .pipe(
      tap(_ => console.log("loaded whiskies from REST API")),
      catchError(this.handleError<Whisky[]>('getAllWhiskies', []))
    );
  }


  public verifyWhisky(whiskyId: string): Observable<HttpResponse<string>> {
    return this.http
    .get(WHISKYBASE_URL + whiskyId, {headers: HEADER, observe: "response", responseType: 'text'})
    .pipe(
      tap(_ => console.log("Checked whisky against Whiskybase database: " + whiskyId))
    )
  }

  // API: GET /whiskies/...id...
  public getWhiskyById(whiskyId: number): Observable<Whisky[]>  {
    return this.http
    .get<Whisky[]>(API_URL + whiskyId, { headers: HEADER })
    .pipe(
      tap(_ => console.log("loaded whisky from REST API")),
      catchError(this.handleError<Whisky[]>('getWhiskyById', []))
    );
  }


  public getWhiskyBottlesById(whiskyId: number): Observable<WhiskyBottle[]> {
    return this.http
    .get<WhiskyBottle[]>(API_URL + whiskyId + "/bottles", { headers: HEADER })
    .pipe(
      tap(_ => console.log("loaded bottles from REST API")),
      catchError(this.handleError<WhiskyBottle[]>('getWhiskyBottlesById', []))
    );      
  }

  public addnewBottle(whiskybottle: WhiskyBottle): Observable<HttpResponse<string>> {
    let body = {
      whisky: whiskybottle.whiskyId,
      status: whiskybottle.status,
      added: whiskybottle.added,
      opened: whiskybottle.opened,
      finished: whiskybottle.finished,
      paid: whiskybottle.paid
    };
    return this.http
    .post(API_URL + whiskybottle.whiskyId + "/bottles", body,  {headers: {"enctype": "multipart/form-data", 'Authorization': AUTH_DATA}, observe: "response", responseType: 'text'})
    .pipe(
      tap(_ => console.log("sent new bottle to rest API"))
    )
  }



/**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
private handleError<T>(operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    // Log error to console
    console.error(`${operation} failed: ${error.message}`)

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

}
