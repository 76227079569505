import { BottleState } from './whisky';

export class WhiskyBottle {
    public id: number;
    public whiskyId: number;
    public paid: number;
    public status: BottleState;
    public added: Date;
    public opened: Date;
    public finished: Date;

}