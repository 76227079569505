// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // URL of development API
  apiUrl: 'https://api.huggera.ch/local_bar/whisky/',

  // URL for CORS call to whiskybase
  whiskybaseUrl: 'https://api.huggera.ch/load.php?url=https://www.whiskybase.com/whiskies/whisky/',

  // Username for accessing API
  apiUserName: 'ahu',

  // Password for accessing API
  apiPassword: 'km31pvz'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
