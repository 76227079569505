<div class="admin-toolbar">
    <button mat-button [routerLink]="'/'" color="primary">Local Bar</button>
    <span> / </span>
    <button mat-button [routerLink]="'/admin'" color="primary">Settings</button>
    <span> / </span>
    <button mat-button disabled>Bottle Overview</button>
</div>

<mat-tab-group mat-align-tabs="start">    
    <mat-tab label="Whisky Bottles">
        <whisky-admin></whisky-admin>
    </mat-tab>
    <!-- <mat-tab label="Wine"></mat-tab> -->
</mat-tab-group>
