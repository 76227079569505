<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button  (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1>The local bar menu</h1>
    <span class="toolbar-spacer"></span>
    <app-login-icon></app-login-icon>

  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container id="nav-container">
  <mat-sidenav #sidenav mode="over">
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/whisky'" (click)="sidenav.close()">
        <img src="/assets/icons/whiskey_empty.png" class="nav-image" /> Whisky
      </a>
      <a mat-list-item [routerLink]="'/wine'" (click)="sidenav.close()">
        <span class="material-icons nav-icon">wine_bar</span>Wine
      </a>
    <a mat-list-item [routerLink]="'/admin'" (click)="sidenav.close()">
      <span class="material-icons nav-icon">admin_panel_settings</span>Settings
    </a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="content">
      <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>



