import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Whisky } from '../../../model/whisky';
import { MatRipple } from '@angular/material/core';

@Component({
  selector: 'whisky-card',
  templateUrl: './whisky-card.component.html',
  styleUrls: ['./whisky-card.component.less']
})

export class WhiskyCardComponent implements OnInit {

  // Input whisky to render
  @Input() whisky: Whisky;

  // Is card collapsed or not
  collapsed: boolean;

  /** Reference to the directive instance of the ripple. */
  @ViewChild(MatRipple) ripple: MatRipple;



  constructor(
      public breakpointObserver: BreakpointObserver,
      private router: Router
      ) { }

  ngOnInit(): void {
      this.breakpointObserver
      .observe([Breakpoints.Small, Breakpoints.HandsetPortrait])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.collapsed = true;
        } else {
          this.collapsed = false;
        }
      });
   }

  openWhisky(): void {
    const enterDuration = 300;
    const exitDuration = 50;
    const animationDuration = enterDuration + exitDuration;

    this.ripple.launch({centered: true, animation: {enterDuration, exitDuration} });
    setTimeout(() => this.router.navigate(['/whisky/'+this.whisky.id]), animationDuration);
  }

  collapse(event: MouseEvent) {
    this.collapsed = true;
    event.stopPropagation();
  }

  unfold(event: MouseEvent) {
    this.collapsed = false;
    event.stopPropagation();
  }

}
