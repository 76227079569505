<div>
    <mat-form-field style="width: 350px;">
        <mat-label>Bottle state</mat-label>
        <mat-select [formControl]="bottleStateControl" multiple>
      
          <mat-select-trigger>
            <mat-chip-list>
                <mat-chip *ngFor="let state of bottleStateControl.value"
                [removable]="true" (removed)="onStateRemoved(state)">
                {{ state }}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </mat-select-trigger>
      
          <mat-option *ngFor="let state of bottleStateList" [value]="state">{{state}}</mat-option>
      
        </mat-select>
      </mat-form-field>
    <br />
    <ng-container *ngFor="let w of filteredCollection">
        <whisky-card [whisky]="w"></whisky-card>
    </ng-container>
</div>