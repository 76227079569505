import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { AccountService } from '../admin.service';
import { User } from '../user';

@Component({
  selector: 'app-login-icon',
  templateUrl: './login-icon.component.html',
  styleUrls: ['./login-icon.component.less']
})
export class LoginIconComponent implements OnInit {

  private _icon: string;
  private _username: string;
  private isSignedIn: boolean = false;

  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountService: AccountService
) {
  // subscribe to user changes
  this.subscription = this.accountService.user.subscribe(x => {
    this.updateIcon();
    this.updateUserName();
  });

 }

  ngOnInit(): void {
    const user = this.accountService.user;
    if (user) {
      this.isSignedIn = true;
    }
    this.updateIcon();
  }

  public get icon() {
    return this._icon;
  }

  public get username() {
    return this._username;
  }

  private updateIcon() {
    const user = this.accountService.userValue;
    if (user) {
      this._icon = "logout";
    } else {
      this._icon = "login";
    }
  }

  private updateUserName() {
    const user = this.accountService.userValue;
    if (user) {
      this._username = user.displayName;
    } else {
      this._username = "";
    }
  }

  signInOut(): void {
    const user = this.accountService.userValue;
    if (user) {
      this.logout();
    } else {
      this.login();
    }
  }

  login(): void {
    this.router.navigate(['/login']);
  }

  logout(): void {
    this.accountService.logout();
  }

}
