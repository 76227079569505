<div style="max-width: 600px; padding: 10px;">
<mat-form-field>
    <mat-label>Username</mat-label>
    <input matInput placeholder="Username" [(ngModel)]="this.username" required>
  </mat-form-field>
  <br />
  <mat-form-field>
    <input matInput placeholder="Password" type="password" [(ngModel)]="this.password" required>
  </mat-form-field>
<br />
    <button mat-raised-button color="primary" type="submit" (click)="submit()">Login</button>
</div>
