<div class="admin-toolbar">
    <button mat-button [routerLink]="'/'" color="primary">Local Bar</button>
    <span> / </span>
    <button mat-button [routerLink]="'/admin'" color="primary">Settings</button>
    <span> / </span>
    <button mat-button disabled color="primary">Whisky Bottles</button>
    <span> / </span>
    <button mat-button disabled>{{whisky.name}}</button>
</div>

<!-- whisky image -->
<div style="float: left; min-width: 200px; width: 20%;">
    <img src="{{whisky.photoPreviewUrl}}" style="display:block; margin-left: auto; margin-right: auto; padding-bottom: 20px;" />
</div>

<!-- whisky information -->
<div style="float:right; width: 80%; min-width: 350px;">
    <div class="mat-card">
        <div style="padding: 20px;">
            <h1 style="margin-bottom: 0px;">{{whisky.name}}</h1>
        </div>
        <mat-divider></mat-divider>

        <!-- whisky details -->
        <div style="padding: 20px;">
            <div style="float: left; width: 33%;  min-width: 300px;">
                <!-- distilleries -->
                <p class="whisky-header">Distilleries</p>
                <p class="whisky-content">{{whisky.distilleries}}</p>
        
                <!-- category -->
                <p class="whisky-header">Category</p>
                <p class="whisky-content">{{whisky.category}}</p>
        
                <!-- age and vintage -->
                <ng-template [ngIf]="whisky.age && !whisky.vintage">
                    <p class="whisky-header">Age</p>
                    <p class="whisky-content">{{whisky.age}} years</p>
                </ng-template>
                <ng-template [ngIf]="whisky.vintage && !whisky.age">
                    <p class="whisky-header">Vintage</p>
                    <p class="whisky-content">{{whisky.vintage}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.age && whisky.vintage">
                    <p class="whisky-header">Age</p>
                    <p class="whisky-content">{{whisky.age}} years ({{whisky.vintage}})</p>
                </ng-template>
                <ng-template [ngIf]="!whisky.age && !whisky.vintage">
                    <p class="whisky-header">Age</p>
                    <p class="whisky-content">NAS</p>
                </ng-template>
        
                <!-- strength -->
                <p class="whisky-header">Strength</p>
                <p class="whisky-content">{{whisky.strength}}{{whisky.strengthUnit}}</p>
        
                <!-- region -->
                <p class="whisky-header">Region</p>
                <p class="whisky-content">{{whisky.region}}</p>
            </div>
            <div style="float: left; width: 33%; min-width: 300px;">
                <ng-template [ngIf]="whisky.caskType!= null">
                    <p class="whisky-header">Cask</p>
                    <p class="whisky-content">{{whisky.caskType}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.caskNumber != null">
                    <p class="whisky-header">Cask Number</p>
                    <p class="whisky-content">{{whisky.caskNumber}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.numberOfBottles != null">
                    <p class="whisky-header">Number of Bottles</p>
                    <p class="whisky-content">{{whisky.numberOfBottles}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.myRating != null">
                    <p class="whisky-header">My Rating (x/100)</p>
                    <p class="whisky-content">{{whisky.myRating}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.value != null">
                    <p class="whisky-header">Value (CHF)</p>
                    <p class="whisky-content">{{whisky.value}}</p>
                </ng-template>
            </div>
            <div style="float: left; width: 33%; min-width: 300px;">
                <ng-template [ngIf]="whisky.bottledBy!= null">
                    <p class="whisky-header">Bottled by</p>
                    <p class="whisky-content">{{whisky.bottledBy}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.bottling != null">
                    <p class="whisky-header">Bottling Serie</p>
                    <p class="whisky-content">{{whisky.bottling}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.bottledFor != null">
                    <p class="whisky-header">Bottled for</p>
                    <p class="whisky-content">{{whisky.bottledFor}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.bottled != null">
                    <p class="whisky-header">Bottled</p>
                    <p class="whisky-content">{{whisky.bottled}}</p>
                </ng-template>
            </div>
            <br style="clear:both" />
        </div>
        <mat-divider></mat-divider>

        <!-- bottle overview -->
        <div style="padding: 20px;">
            <p>
                <button mat-raised-button color="primary" (click)="addNewBottle()">Add new bottle</button>
            </p>
            <table mat-table [dataSource]="dataSource" matSort class="bottle-table">
        
                <!-- Image / Status Column -->
                <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>&nbsp;</th>
                <td mat-cell *matCellDef="let element" class="whisky-bottle-icon-cell">
                    <div class="whisky-status-icon whisky-status-icon-{{element.status}}"></div>
                </td>
                </ng-container>
        
                <!-- Bottle status -->
                <ng-container matColumnDef="label">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Bottle state </th>
                <td mat-cell *matCellDef="let element"> {{element.label}} </td>
                </ng-container>
        
                <!-- Added -->
                <ng-container matColumnDef="added">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Added </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.added">
                        {{element.added | date: 'dd.MM.yyyy'}} 
                    </ng-container>
                </td>
                </ng-container>
        
                <!-- Opened -->
                <ng-container matColumnDef="opened">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Opened </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.opened">
                        {{element.opened | date: 'dd.MM.yyyy'}} 
                    </ng-container>
                </td>
                </ng-container>
        
                <!-- Finished -->
                <ng-container matColumnDef="finished">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Finished </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.finished">
                        {{element.finished | date: 'dd.MM.yyyy'}} 
                    </ng-container>
                </td>
                </ng-container>
        
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row mat-ripple [matRippleDisabled]="true" *matRowDef="let row; let index = index; columns: displayedColumns;" (click)="editBottle(row, index)"></mat-row>

            </table>
            
            <mat-paginator [pageSizeOptions]="[10, 25, 100]" showFirstLastButtons></mat-paginator>
        </div>
    </div>
</div>
<br style="clear: both;" />
