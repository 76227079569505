<div class="whisky-admin-list">
    <button mat-raised-button [routerLink]="'/admin/create-whisky'">Add new Whisky</button>
    &nbsp;
    <mat-form-field  style="max-width: 600px;" >
      <mat-label>Filter Whisky List</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Search query" #input>
    </mat-form-field>
    
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Whiskybase Id </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Brand Column -->
        <ng-container matColumnDef="brand">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Brand </th>
          <td mat-cell *matCellDef="let element"> {{element.brand}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.status}} </td>
        </ng-container>

        <!-- Number of bottles in collection Column -->
        <ng-container matColumnDef="numberOfBottlesInCollection">
          <th mat-header-cell *matHeaderCellDef mat-sort-header># of Bottles</th>
          <td mat-cell *matCellDef="let element"> {{element.numberOfBottlesInCollection}} </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row mat-ripple [matRippleDisabled]="true" *matRowDef="let row; let index = index; columns: displayedColumns;" (click)="editWhisky(row.id, index)" class="hover"></mat-row>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
        </tr>
      </table>

      <mat-paginator [pageSizeOptions]="[25, 100]" showFirstLastButtons></mat-paginator>
    </div>
</div>
