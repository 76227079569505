import { AfterViewInit, Component, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';

import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatRipple } from '@angular/material/core';

import { WhiskyService } from '../../../services/whisky.service';
import { Whisky } from '../../../model/whisky';


export interface WhiskyElement {
  id: number;
  name: string;
  brand: string;
  status: string;
}


@Component({
  selector: 'whisky-admin',
  templateUrl: './whisky-admin.component.html',
  styleUrls: ['./whisky-admin.component.less']
})

export class WhiskyAdminComponent implements AfterViewInit   {

  whiskyCollection: Whisky[] = [];
  whiskyDataSource: WhiskyElement[] = [];

  displayedColumns: string[] = ['id', 'name', 'brand', 'status', 'numberOfBottlesInCollection'];
  dataSource: MatTableDataSource<WhiskyElement>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  /** Reference to the directive instance of the ripple. */
  @ViewChildren(MatRipple) rippleList: QueryList<MatRipple>;

  constructor(
      private whiskyService: WhiskyService,
      private router: Router
    ) { }

  ngAfterViewInit() {
    this.whiskyService.getAllWhiskies()
    .subscribe(
      (whiskies) => { 
        whiskies.forEach(whisky => {
          let w = {
            id: whisky.id,
            name: whisky.name,
            brand: whisky.brand,
            status: whisky.status,
            numberOfBottlesInCollection: whisky.numberOfBottlesInCollection
          };
          this.whiskyDataSource.push(w);
        });
        this.dataSource = new MatTableDataSource(this.whiskyDataSource);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      } 
    );
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  editWhisky(whiskyId, index): void {
    const enterDuration = 300;
    const exitDuration = 50;
    const animationDuration = enterDuration + exitDuration;

    let ripples = this.rippleList.toArray()
    ripples[index].launch({centered: true, animation: {enterDuration, exitDuration} });

    setTimeout(() => this.router.navigate(['/admin/whisky/'+ whiskyId]), animationDuration);
  }

}
