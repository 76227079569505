import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WhiskyListComponent } from './components/whisky/whisky-list/whisky-list.component';
import { WineListComponent } from './components/wine/wine-list/wine-list.component';
import { AdminComponent } from './components/admin/admin/admin.component';
import { WhiskyEditComponent } from './components/admin/whisky-edit/whisky-edit.component';
import { WhiskyAddComponent } from './components/admin/whisky-add/whisky-add.component';
import { WhiskyDetailsComponent } from './components/whisky/whisky-details/whisky-details.component';
import { WhiskyBottleAdminComponent } from './components/admin/whisky-bottle-admin/whisky-bottle-admin.component';

import { AuthGuard } from './auth/auth.gard';
import { LoginFormComponent } from './auth/login-form/login-form.component';

const routes: Routes = [
  { path: 'whisky' ,
    children: [
      { path: '', component: WhiskyListComponent, pathMatch: 'full' },
      { path: ':whiskyId', component: WhiskyDetailsComponent }
    ]
  },
  { path: 'wine' , component: WineListComponent },
  { path: 'login', component: LoginFormComponent },
  { 
    path: 'admin', 
    children: [
      { path: '', component: AdminComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'create-whisky', component: WhiskyAddComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'edit-whisky/:whiskyId', component: WhiskyEditComponent, canActivate: [AuthGuard]},
      { path: 'whisky/:whiskyId', component: WhiskyBottleAdminComponent, canActivate: [AuthGuard]}
    ] 
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
