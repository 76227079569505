import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Whisky } from '../model/whisky';
import { WhiskyBottle } from '../model/whiskyBottle';
import { APIService } from './api.service';

import { NgxCsvParser, NgxCSVParserError } from 'ngx-csv-parser';
import { Observable, of } from 'rxjs';


@Injectable({
    providedIn: 'root'
})

export class WhiskyService {

    private whiskyCollection: Whisky[] = [];

    private parsedWhiskyname: string;

    constructor(
        private http: HttpClient,
        private ngxCsvParser: NgxCsvParser,
        private api: APIService
    ) { }

    getAllWhiskies(): Observable<Whisky[]> {
        return this.api.getAllWhiskies();
    }

    getWhiskyById(id: number): Observable<Whisky[]> {
        return this.api.getWhiskyById(id);
    }

    getWhiskyBottlesById(id: number): Observable<WhiskyBottle[]> {
        return this.api.getWhiskyBottlesById(id);
    }

    updateWhisky() {

    }

    verifyWhiskyId(id: string): Observable<HttpResponse<string>> {
        return this.api.verifyWhisky(id);
    }

    addNewBottle(whiskybottle: WhiskyBottle): Observable<HttpResponse<string>> {
        return this.api.addnewBottle(whiskybottle);
    }

}