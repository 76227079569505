<h2 mat-dialog-title>Bottle Update</h2>
<div mat-dialog-content class="bottle-dialog">
  <mat-form-field>
    <mat-label>Bottle status</mat-label>
    <mat-select [(value)]="data.bottle.status">
      <mat-option value="closed">Closed</mat-option>
      <mat-option value="open">Open</mat-option>
      <mat-option value="empty">Empty</mat-option>
    </mat-select>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Bought / Added to collection</mat-label>
    <input matInput [matDatepicker]="addedPicker" [(ngModel)]="data.bottle.added">
    <mat-datepicker-toggle matSuffix [for]="addedPicker"></mat-datepicker-toggle>
    <mat-datepicker #addedPicker></mat-datepicker>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Opened</mat-label>
    <input matInput [matDatepicker]="openedPicker" [(ngModel)]="data.bottle.opened">
    <mat-datepicker-toggle matSuffix [for]="openedPicker"></mat-datepicker-toggle>
    <mat-datepicker #openedPicker></mat-datepicker>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Finished</mat-label>
    <input matInput [matDatepicker]="finishedPicker" [(ngModel)]="data.bottle.finished">
    <mat-datepicker-toggle matSuffix [for]="finishedPicker"></mat-datepicker-toggle>
    <mat-datepicker #finishedPicker></mat-datepicker>
  </mat-form-field>
  <br />
  <mat-form-field>
    <mat-label>Paid (in CHF)</mat-label>
    <input matInput [(ngModel)]="data.bottle.paid">
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="data" color="primary" cdkFocusInitial>Submit</button>
</div>