import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wine-list',
  templateUrl: './wine-list.component.html',
  styleUrls: ['./wine-list.component.less']
})
export class WineListComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
