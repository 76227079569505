<mat-card matRipple [matRippleDisabled]="true" class="whisky-card" (click)="openWhisky()">
    <div>
    <mat-card-header style="height: 60px;">
      <div mat-card-avatar class="whisky-status-icon-{{whisky.status}}"></div>
      <mat-card-title>{{whisky.name}}</mat-card-title>
      <mat-card-subtitle>{{whisky.bottling}}</mat-card-subtitle>
      <span class="whisky-card-spacer"></span>
      <span class="whisky-expand-icon">
        <mat-icon  *ngIf="collapsed" (click)="collapsed=false; $event.stopPropagation();">expand_more</mat-icon>
        <mat-icon  *ngIf="!collapsed" (click)="collapsed=true; $event.stopPropagation();">expand_less</mat-icon>
      </span>
    </mat-card-header>
    
    <mat-card-content *ngIf="!collapsed">
        <mat-divider></mat-divider>
        <div class="whisky-card-details">
            <div class="whisky-card-left">
                <p class="whisky-header">Distilleries</p>
                <p class="whisky-content">{{whisky.distilleries}}</p>

                <p class="whisky-header">Category</p>
                <p class="whisky-content">{{whisky.category}}</p>

                <!-- age and vintage -->
                <ng-template [ngIf]="whisky.age && !whisky.vintage">
                    <p class="whisky-header">Age</p>
                    <p class="whisky-content">{{whisky.age}} years</p>
                </ng-template>
                <ng-template [ngIf]="whisky.vintage && !whisky.age">
                    <p class="whisky-header">Vintage</p>
                    <p class="whisky-content">{{whisky.vintage}}</p>
                </ng-template>
                <ng-template [ngIf]="whisky.age && whisky.vintage">
                    <p class="whisky-header">Age</p>
                    <p class="whisky-content">{{whisky.age}} years ({{whisky.vintage}})</p>
                </ng-template>
                <ng-template [ngIf]="!whisky.age && !whisky.vintage">
                    <p class="whisky-header">Age</p>
                    <p class="whisky-content">NAS</p>
                </ng-template>

                <!-- strength -->
                <p class="whisky-header">Strength</p>
                <p class="whisky-content">{{whisky.strength}}{{whisky.strengthUnit}}</p>

                <!-- region -->
                <p class="whisky-header">Region</p>
                <p class="whisky-content">{{whisky.region}}</p>
            </div>
            <div class="whisky-card-right">
                <img class="whisky-card-bottle" alt="bottle picture" src="{{whisky.photoPreviewUrl}}" />
            </div>
        </div>      
    </mat-card-content>
</div>
</mat-card>
