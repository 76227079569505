import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { MatGridListModule } from '@angular/material/grid-list';

import { BottleState, Whisky } from 'src/app/model/whisky';
import { WhiskyBottle } from 'src/app/model/whiskyBottle'
import { WhiskyService } from 'src/app/services/whisky.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

export interface WhiskyBottleElement {
  status: string;
  label: string;
  added: Date;
  opened: Date;
  finished: Date;
}

@Component({
  selector: 'app-whisky-details',
  templateUrl: './whisky-details.component.html',
  styleUrls: ['./whisky-details.component.less']
})
export class WhiskyDetailsComponent implements OnInit {

  displayedColumns: string[] = ['status', 'label', 'added', 'opened', 'finished'];
  internalDataSource: WhiskyBottleElement[] = [];
  dataSource: MatTableDataSource<WhiskyBottleElement>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public whiskyId: number;
  public whisky: Whisky;

  constructor(
      private whiskyService: WhiskyService,
      private activatedRoute: ActivatedRoute
      ) { }

  ngOnInit(): void {
    var snapshot = this.activatedRoute.snapshot;
    this. whiskyId = Number(snapshot.params.whiskyId);
    console.log(this.whiskyId);

    
    if (this.whiskyId != 0) {

      // Load whisky from database
      this.whiskyService.getWhiskyById(this.whiskyId)
      .subscribe(
        (whiskies) => { 
          whiskies.forEach(whisky => {
            this.whisky = whisky;
          });
        });

      // Load bottles from database
      this.whiskyService.getWhiskyBottlesById(this.whiskyId)
      .subscribe(
          (bottles) => {
            bottles.forEach(bottle => {
              let b = {
                status: bottle.status,
                label: bottle.status,
                added: null,
                opened: null,
                finished: null
              };
              if (bottle.added != null) {
                b.added = new Date(bottle.added);
              }
              if (bottle.opened != null) {
                b.opened = new Date(bottle.opened);
              }
              if (bottle.finished != null) {
                b.finished = new Date(bottle.finished);
              }
              this.internalDataSource.push(b);
            });
            this.dataSource = new MatTableDataSource(this.internalDataSource);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          });
    }

    

    
  }

}
