<div class="admin-toolbar">
    <button mat-button [routerLink]="'/'" color="primary">Local Bar</button>
    <span> / </span>
    <button mat-button [routerLink]="'/admin'" color="primary">Settings</button>
    <span> / </span>
    <button mat-button disabled>Add a whisky</button>
</div>
<form>
    <div  class="mat-card" style="width: 100%;">
        <div style="max-width: 600px; padding: 10px;">
            <mat-form-field appearance="standard" style="width: 100%;">
                <mat-label>Whiskybase Id</mat-label>
                <input matInput placeholder="12345" [(ngModel)]="whiskybaseId" name="whiskybaseId" (ngModelChange)="onWhiskyIdChange()">
            </mat-form-field>
            <div *ngIf="parsedWhiskyname" class="detail-load-button">
                <button mat-raised-button color="primary" (click)="loadWhiskyData()" style="margin-right: 30px;">Load data from Whiskybase ({{this.parsedWhiskyname}})</button>
                <mat-icon>open_in_new</mat-icon><a href="https://www.whiskybase.com/whiskies/whisky/{{this.whiskybaseId}}" target="_blank">Visit whisky on Whiskybase</a>
            </div>
        </div>
        <mat-divider></mat-divider>
        <div style="padding: 10px;">
            <h3>Whisky Detail Information</h3>
            <div style="float: left; width: calc(33% - 10px);  min-width: 350px; padding-right: 10px;">

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Brand</mat-label>
                    <input matInput placeholder="Laphroaig" [(ngModel)]="brand" name="brand">
                    <mat-hint>Name of producer or distillery</mat-hint>
                </mat-form-field>
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Distilleries</mat-label>
                    <input matInput placeholder="Laphroaig, Coal Ila, Lagavulin" [(ngModel)]="distilleries" name="distilleries">
                    <mat-hint>For blends: Add all distilleries comma separated</mat-hint> 
                </mat-form-field>

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Age</mat-label>
                    <input matInput placeholder="12" [(ngModel)]="age" name="age">
                </mat-form-field>           
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Vintage</mat-label>
                    <input matInput placeholder="1985" [(ngModel)]="vintage" name="vintage">
                </mat-form-field> 

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Category</mat-label>
                    <input matInput placeholder="Single Malt" [(ngModel)]="category" name="category">
                    <mat-hint>Single Malt, Single Cask, Blended Malt, Bourbon, ...</mat-hint>
                </mat-form-field> 

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Region</mat-label>
                    <input matInput placeholder="Speyside (Scotland)" [(ngModel)]="region" name="region">
                </mat-form-field> 

            </div>
            <div style="float: left; width: calc(33% - 10px); min-width: 350px; padding-right: 10px;">

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Strength</mat-label>
                    <input matInput placeholder="43.8" [(ngModel)]="strength" name="strength">
                </mat-form-field> 
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Strength Unit</mat-label>
                    <input matInput placeholder="% Vol." [(ngModel)]="strengthUnit" name="strengthUnit">
                </mat-form-field>         

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Cask Type</mat-label>
                    <input matInput placeholder="Oloroso Sherry Cask" [(ngModel)]="caskType" name="caskType">
                </mat-form-field> 
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Cask Number</mat-label>
                    <input matInput [(ngModel)]="caskNumber" name="caskNumber">
                </mat-form-field>   

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Number of Bottles</mat-label>
                    <input matInput placeholder="233" [(ngModel)]="numberOfBottles" name="numberOfBottles">
                </mat-form-field> 

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Value</mat-label>
                    <input matInput placeholder="233" [(ngModel)]="value" name="value">
                </mat-form-field> 

            </div>
            <div style="float: left; width: calc(33% - 10px); min-width: 350px; padding-right: 10px;">

                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Bottled by</mat-label>
                    <input matInput placeholder="Signatory Vintage (SV)" [(ngModel)]="bottledBy" name="bottledBy">
                    <mat-hint>State independent bottler like Signatory Vintage</mat-hint>
                </mat-form-field>           
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Bottled for</mat-label>
                    <input matInput placeholder="World of Whisky (Switzerland)" [(ngModel)]="bottledFor" name="bottledFor">
                    <mat-hint>Special release for a shop or an event</mat-hint>
                </mat-form-field>   
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Bottling</mat-label>
                    <input matInput placeholder="The First Editions" [(ngModel)]="bottling" name="bottling">
                    <mat-hint>Bottling series like "The First Editions" or "SV Cask Strength Series"</mat-hint>
                </mat-form-field>           
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Bottled at</mat-label>
                    <input matInput placeholder="28.01.2013" [(ngModel)]="bottled" name="bottled">
                </mat-form-field>   


            </div>
        </div>
        <mat-divider style="clear: both;"></mat-divider>
        <div style="max-width: 600px; padding: 10px;">
            <h3>Image Links</h3>
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Photo preview URL</mat-label>
                    <input matInput placeholder="https://..." [(ngModel)]="photoPreviewUrl" name="photoPreviewUrl">
                </mat-form-field> 
                <mat-form-field appearance="standard" style="width: 100%;">
                    <mat-label>Photo URL</mat-label>
                    <input matInput placeholder="https://..." [(ngModel)]="photoUrl" name="photoUrl">
                </mat-form-field> 

        </div>
        <mat-divider></mat-divider>
        <div style="max-width: 600px; padding: 10px;">
            <button mat-raised-button color="primary" type="submit" (click)="submitWhisky()">Add whisky to database</button>
        </div>
    </div>
</form>